<template>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card form-body">
        <div class="card-body">
          <form>
            <div class="d-flex">
              <legend><h4>Company Information</h4></legend>

              <div class="form-group mr-5" style="width:50%">
                <label for="">Use In Archive</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="use_in_archive"
                    class="switch"
                    id="use_in_archive_switch"
                  />
                  <label for="use_in_archive_switch"></label>
                </span>

                <!-- <label for="" v-else>Hide Payment Option</label> -->
              </div>

              <div class="form-group">
                <label for="">Active</label>
                <span class="switch">
                  <input
                    type="checkbox"
                    v-model="is_active"
                    class="switch"
                    id="status_switch"
                  />
                  <label for="status_switch"></label>
                </span>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Name <span class="text-danger">*</span></label>
                  <input
                    v-model="company_name"
                    type="text"
                    class="form-control"
                    :class="
                      errors['company_name'] ? 'border border-danger' : ''
                    "
                  />
                  <span v-if="errors['company_name']" class="text-danger">{{
                    errors["company_name"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""
                    >Address <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    name=""
                    rows="1"
                    :class="errors['address'] ? 'border border-danger' : ''"
                    v-model="address"
                    class="form-control"
                    id=""
                  />

                  <span v-if="errors['address']" class="text-danger">{{
                    errors["address"][0]
                  }}</span>
                </div>
              </div>
            </div>

            <legend><h6>Contact Information</h6></legend>
            <hr />

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Name<span class="text-danger">*</span></label>
                  <input
                    v-model="contact_name"
                    type="text"
                    class="form-control"
                    :class="errors['address'] ? 'border border-danger' : ''"
                  />
                  <span v-if="errors['contact_name']" class="text-danger">{{
                    errors["contact_name"][0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for=""
                    >Phone Number<span class="text-danger">*</span></label
                  >
                  <input
                    v-model="contact_phone_no"
                    type="text"
                    class="form-control"
                    v-mask="'(###)-###-####'"
                    :class="
                      errors['contact_phone_number']
                        ? 'border border-danger'
                        : ''
                    "
                  />
                  <span
                    v-if="errors['contact_phone_number']"
                    class="text-danger"
                    >{{ errors["contact_phone_number"][0] }}</span
                  >
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for=""
                >Email Address<span class="text-danger">*</span></label
              >
              <input
                v-model="contact_email"
                type="email"
                class="form-control"
                :class="errors['contact_email'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['contact_email']" class="text-danger">{{
                errors["contact_email"][0]
              }}</span>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            @click="update('kt_update_company_data')"
            ref="kt_update_company_data"
            style="float: right"
            v-if="isEdit"
          >
            <i class="fa fa-check"></i> Update
          </button>

          <button
            class="btn btn-success ml-3"
            style="float: right"
            v-if="!isEdit"
            ref="kt_save_company"
            @click="store('S', 'kt_save_company')"
          >
            <i class="fa fa-check"></i> Save
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-success"
            @click="store('SAA', 'kt_save_add_another_company')"
            style="float: right"
            v-if="!isEdit"
          >
            <i class="fa fa-check"></i> Save and add another
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  STORE_MASTER_COMPANY,
  GET_MASTER_COMPANY_DATA,
  UPDATE_MASTER_COMPANY
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Companies",
          route: "client.master.company"
        },
        {
          id: 3,
          title: "Create New Company",
          route: ""
        }
      ],
      company_name: "",
      address: "",
      contact_name: "",
      contact_email: "",
      contact_phone_no: "",
      use_in_archive: false,
      is_active: true,
      errors: []
    };
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.isEdit =
      this.$route.name == "client.master.company.edit" ? true : false;
    var id = this.$route.params.id;
    if (this.isEdit) {
      this.breadCrumbs[2].title = "";
      this.$store
        .dispatch(GET_MASTER_COMPANY_DATA, id)
        .then(data => {
          this.breadCrumbs[2].title = "Edit | " + data.company_name;
          this.company_name = data.company_name;

          this.address = data.address;
          this.contact_name = data.contact_name;
          this.contact_email = data.contact_email;
          this.contact_phone_no = data.contact_phone_number;
          this.is_active = data.is_active;

          this.use_in_archive = data.use_in_archive;
        })
        .catch(() => {
          this.$toastr.e("Company detail not found!");
          this.$router.push({ name: "client.master.company" });
        });
    }
  },
  methods: {
    notAfterToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.master.company",
        params: { client_slug: client_slug }
      });
    },
    clearForm() {
      this.company_name = "";
      this.address = "";
      this.contact_name = "";
      this.contact_email = "";
      this.contact_phone_no = "";
      this.use_in_archive = false;
      this.is_active = true;
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    update(ref) {
      this.errors = [];
      this.loadingButton(ref);
      var data_ = {
        company_name: this.company_name,
        address: this.address,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_no,
        is_active: this.is_active,
        use_in_archive: this.use_in_archive
      };
      this.$store
        .dispatch(UPDATE_MASTER_COMPANY, {
          id: this.$route.params.id,
          data: data_
        })
        .then(data => {
          if (data.code == 200) {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            this.$router.push({ name: "client.master.company" });
          } else {
            this.$toastr.w(data.msg);
            this.closeBtnLoad(ref);
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
    store(saveType, ref) {
      this.errors = [];
      this.loadingButton(ref);
      var data_ = {
        company_name: this.company_name,
        address: this.address,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone_number: this.contact_phone_no,
        is_active: this.is_active,
        use_in_archive: this.use_in_archive
      };
      this.$store
        .dispatch(STORE_MASTER_COMPANY, data_)
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({ name: "client.master.company" });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    }
  }
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
